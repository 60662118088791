<template>
  <div class="modal"  :class="{ 'is-active': active }">
    <div class="modal-background"></div>
    <form
      class="modal-card"
      @submit.prevent="onSubmit"
    >
      <header class="modal-card-head">
        <p class="modal-card-title has-text-centered">Nueva notificación</p>
        <button class="delete" aria-label="close" type="reset" @click="$emit('close-modal')" />
      </header>
      <section class="modal-card-body">
        <div class="columns is-multiline is-centered">
          <div class="column is-12">
            <div class="field">
              <label class="label">Elige el grupo para enviar la notificación</label>
              <div class="control">
                <div class="select is-fullwidth">
                  <select
                    name="whoDoWeSend"
                    v-model="whoDoWeSend"
                    data-vv-as="del grupo para enviar la notificación"
                  >
                    <option v-for="item in optionSend" :key="item.id" :value="item.id">
                      {{ item.name }}
                    </option>
                  </select>
                </div>
              </div>
              <span class="help is-danger"> {{vverrors.first('whoDoWeSend')}} </span>
            </div>
          </div>
          <div class="column is-12">
            <div class="field">
              <label class="label">Titulo de la notificación</label>
              <div class="control">
                <input
                  class="input"
                  placeholder="Titulo"
                  name="title"
                  v-model="title"
                  v-validate="'required'"
                  data-vv-as="del titulo de la notificación"
                />
              </div>
              <span class="help is-danger"> {{vverrors.first('title')}} </span>
            </div>
          </div>
          <div class="column is-12">
            <div class="field">
              <label class="label">Mensaje de la notificación</label>
              <div class="control">
                <textarea
                  class="textarea has-fixed-size"
                  placeholder="Mensaje"
                  name="message"
                  v-model="message"
                  v-validate="'required'"
                  data-vv-as="del mensaje de la notificación"
                />
              </div>
              <span class="help is-danger"> {{vverrors.first('message')}} </span>
            </div>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot">
        <button
          :disabled="loading"
          class="button is-danger"
          type="reset"
          @click="$emit('close-modal')"
        >
          Cancelar
        </button>
        <button
          class="button button-givu"
          :class="{'is-loading': loading}"
          :disabled="loading"
          type="submit"
        >
          Enviar
        </button>
      </footer>
    </form>
  </div>
</template>

<script>
export default {
  name: 'ModalNewNotification',
  data () {
    return {
      loading: false,
      whoDoWeSend: null,
      message: null,
      title: null,
      optionSend: [
        { id: null, name: 'Global' }
      ]
    }
  },
  props: {
    active: {
      type: Boolean,
      required: true,
      default: false
    },
    sendNotification: {
      type: Function,
      default: () => {}
    },
    groupList: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    async onSubmit () {
      if (await this.$validator.validateAll()) {
        this.loading = true
        const data = {
          title: this.title,
          message: this.message,
          groupId: this.whoDoWeSend
        }
        await this.sendNotification(data)
        this.loading = false
      }
    }
  },
  beforeMount () {
    const list = this.groupList.reduce((acum, element) => {
      acum.push({
        id: element.id, name: `${element.name} - ${element.totalusers} usuarios`
      })
      return acum
    }, [])
    this.optionSend = [ ...this.optionSend, ...list ]
  }
}
</script>

<style lang="scss" scoped>

</style>
